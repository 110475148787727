import { useGet } from "restful-react";
import { useCallback, useEffect, useMemo } from "react";
import { useActiveView, useViewCustomConfig } from "../hooks/deviceDataView";
import { useDataSources } from "../../../Api";
import { useDeviceHistoryData, useDeviceSubscription, useDomainDeviceGroups, useHistoryGetData, useIsHistoryActive, useRNRDeviceState } from "../hooks/server";
import _ from "loadsh";

export function useRnrTrafficState(rnrDriverId, historyState = null) {
    const wsData = useRNRDeviceState(rnrDriverId);

    const { data, loading, refetch, error } = useGet({
        path: "/rnr-project/traffic-state/",
        lazy: true,
        queryParams: {
            rnr_project_device_id: rnrDriverId,
        },
    });

    useEffect(() => {
        if (rnrDriverId && !wsData) {
            refetch();
        }
    }, [rnrDriverId, wsData]);

    const historyActive = useIsHistoryActive();

    // Map the data to a backwards compatible format
    const mappedData = useMemo(() => {
        if (data || wsData || historyState) {
            let startFrom;

            if (historyActive && historyState) {
                startFrom = historyState.project_state;
            } else if (wsData) {
                startFrom = wsData;
            } else {
                startFrom = data;
            }

            if (!startFrom) {
                return null;
            }
            let resp = { ...startFrom };
            const { device_states, rnr_to_device_id } = startFrom;
            if (device_states) {
                resp.device_states = {};
                for (const [key, device_id] of _.entries(rnr_to_device_id)) {
                    resp.device_states[key] = device_states[device_id];
                }
            }
            return resp;
        }
        return null;
    }, [data, wsData, historyState, historyActive]);

    return { rnrTrafficStateData: mappedData, loading, reloadData: refetch, error };
}

export function useBackgroundImage(viewConfig) {
    const { data, refetch } = useGet({
        path: "/view/background_image/",
        lazy: true,
        queryParams: {
            id: viewConfig?.id,
        },
    });

    useEffect(() => {
        if (viewConfig?.id) {
            refetch();
        }
    }, [viewConfig?.id]);

    return useMemo(() => {
        if (data?.data) {
            return JSON.parse(data.data);
        } else {
            return null;
        }
    }, [data]);
}

function getActiveErrors(state) {
    const filterStates = (states) => {
        if (!states?.histogram) {
            return [];
        }
        let ret = [];
        for (const [stateId, state] of Object.entries(states?.histogram)) {
            if (state.is_active) {
                ret.push({
                    id: state?.localized_message?.key ? state?.localized_message?.key : stateId,
                    reason: state?.localized_message?.tokens?.[0],
                    ...state,
                });
            }
        }
        return ret;
    };

    return {
        activeErrors: filterStates(state?.errors),
        activeWarnings: filterStates(state?.warnings),
        activeInfos: filterStates(state?.infos),
    };
}

export const useEndDeviceData = (device, trafficStateData) => {
    return useMemo(() => {
        const rnrState = trafficStateData?.device_states?.[device.rnrId];
        const endDeviceState = rnrState?.state?.current_state?.slices?.[device.sliceId]?.[device.endDeviceId];
        const hasErrorsDevice = endDeviceState?.errors?.is_last_active;
        const hasErrorsRnr = rnrState?.errors?.is_last_active;
        return {
            rnrState,
            endDeviceState: {
                hasCriticalError: hasErrorsRnr | !device.enabled,
                hasError: hasErrorsDevice | hasErrorsRnr,
                ...endDeviceState,
                ...getActiveErrors(endDeviceState),
            },
        };
    }, [trafficStateData, device]);
};

export function useSliceDelay(slice, trafficStateData) {
    const findTrafficStateDataForLane = (rnrId, nrId, deviceId, laneTrafficStateData) => {
        return laneTrafficStateData?.active_traffic_state_detail?.data?.find((data) => data.device_id === deviceId && data.nr_id === nrId && data.rnr_id === rnrId);
    };
    const findTrafficStateDataForDevice = (rnrId, nrId, deviceId, trafficStateData) => {
        let deviceTrafficStateData = null;
        deviceTrafficStateData = findTrafficStateDataForLane(rnrId, nrId, deviceId, trafficStateData?.left_lane);
        if (deviceTrafficStateData) {
            return deviceTrafficStateData;
        }
        deviceTrafficStateData = findTrafficStateDataForLane(rnrId, nrId, deviceId, trafficStateData?.right_lane);
        return deviceTrafficStateData;
    };

    return useMemo(() => {
        const sliceState = trafficStateData?.device_states?.[slice.rnrId]?.state?.current_state?.slices?.[slice.sliceId];
        let delay = 0;
        let hasDifferentSymbols = false;

        for (const [endDeviceId, endDevice] of Object.entries(sliceState ? sliceState : {})) {
            const trafficStateDataForDevice = findTrafficStateDataForDevice(slice.rnrId, slice.sliceId, endDeviceId, trafficStateData);
            const currentSymbols = endDevice?.current_display?.symbols || [];
            const trafficStateSymbols = trafficStateDataForDevice?.symbols || [];
            if (!hasDifferentSymbols) {
                hasDifferentSymbols = !_.isEqual(currentSymbols, trafficStateSymbols);
            }
            delay = Math.max(delay, trafficStateDataForDevice ? trafficStateDataForDevice.delay : 0);
        }
        return hasDifferentSymbols ? delay : 0;
    }, [trafficStateData]);
}

export function useRnrData(device, trafficStateData) {
    return useMemo(() => ({ ...trafficStateData?.device_states?.[device.id], ...getActiveErrors(trafficStateData?.device_states?.[device.id]) }), [trafficStateData, device]);
}

export function useRnrViewContext() {
    const view = useActiveView();
    const viewConfig = useViewCustomConfig();

    const rnrDriverId = viewConfig?.rnr_project?.dataSource;
    const domainId = viewConfig?.rnr_project?.domain;

    const dataSources = useDataSources(domainId);

    const historyDate = useHistoryGetData();

    const dataSource = useMemo(() => dataSources?.dataSources?.find((ds) => ds.id === rnrDriverId), [dataSources, rnrDriverId, view?.id]);

    const filter = useCallback(
        (device) => {
            return device.running_device_id === rnrDriverId;
        },
        [rnrDriverId]
    );
    const devices = useDomainDeviceGroups(domainId, filter);

    const projectDevices = useMemo(() => devices.deviceGroups.flatMap((group) => group.devices), [devices, view?.id]);

    const subsIds = useMemo(() => [...projectDevices, { id: rnrDriverId }], [projectDevices, rnrDriverId]);

    const {
        data: historyData,
        loading: historyLoading,
        refetch: refetchHistory,
        error: historyError,
    } = useGet({
        path: "/rnr-project/history/",
        lazy: true,
        queryParams: {
            data_time: historyDate?.valueOf(),
            rnr_project_device_id: rnrDriverId,
            view_id: view?.id,
        },
    });

    useEffect(() => {
        if (rnrDriverId && historyDate) {
            refetchHistory();
        }
    }, [historyDate, rnrDriverId]);

    useDeviceSubscription(subsIds);
    useDeviceHistoryData(subsIds);

    const { endDevices, rnrDevices, slices } = useMemo(() => {
        let endDevices = [];
        let rnrDevices = [];
        let slices = [];

        projectDevices.forEach((device) => {
            try {
                const config = JSON.parse(device.driver_configuration_json);
                const activeConfig = JSON.parse(config.active_configuration);
                const enabled = device?.enabled;
                let ioDevices = [];

                for (const [sliceId, slice] of Object.entries(activeConfig?.slices)) {
                    slices.push({ id: `${config.rnr_id}_${sliceId}`, slice, sliceId, rnrId: config.rnr_id });
                    for (const [endDeviceId, endDevice] of Object.entries(slice?.devices)) {
                        if (endDevice?.type === "gpio") {
                            ioDevices.push({
                                id: `${config.rnr_id}_${sliceId}_${endDeviceId}`,
                                endDevice,
                                endDeviceId,
                                sliceId,
                                rnrId: config.rnr_id,
                                enabled,
                            });
                        }
                        endDevices.push({
                            id: `${config.rnr_id}_${sliceId}_${endDeviceId}`,
                            endDevice,
                            endDeviceId,
                            sliceId,
                            rnrId: config.rnr_id,
                            enabled,
                            position: slice?.position,
                        });
                    }
                }

                rnrDevices.push({
                    id: config.rnr_id,
                    config,
                    activeConfig,
                    systemDevice: device,
                    ioDevices,
                    enabled,
                });
            } catch (e) {
                console.log(e);
            }
        });
        return { endDevices, rnrDevices, slices };
    }, [projectDevices]);

    const trafficStates = useMemo(() => {
        if (dataSource) {
            try {
                const driverConfig = JSON.parse(dataSource.driver_configuration_json);
                return {
                    leftTrafficStates: JSON.parse(driverConfig.traffic_states_left),
                    rightTrafficStates: JSON.parse(driverConfig.traffic_states_right),
                    driverConfig,
                };
            } catch (e) {
                console.log(e);
                return [];
            }
        } else {
            return [];
        }
    }, [dataSource]);

    return {
        trafficStates,
        rnrDriverId,
        projectDevices,
        endDevices,
        domainId,
        rnrDevices,
        slices,
        historyLoading,
        historyData,
    };
}
