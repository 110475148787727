import { RequirePermission } from "../../../Utils/Permissions/RequirePermission";
import LoadingScope from "../../../Components/LoadingScope";
import { startUsingHistoryData, useHistoryGetData } from "../../../Utils/Data/hooks/server";
import { useHistoryFilter, useRnrAuditLog } from "../../../Api";
import { Last7DaysType } from "../../../Components/Forms/DateRangeField/hooks";
import { useIntl } from "react-intl";
import { DataGridProps, translateCols, useAlertHistoryStyles } from "../../../Components/Charts/AlertHistoryDialogWrapper";
import { Button, useTheme } from "@material-ui/core";
import DateRangePicker from "../../../Components/Forms/DateRangePicker";
import _ from "loadsh";
import { NoDataMessage } from "../../../Components/Forms/NoDataMessage";
import { DataGrid } from "@material-ui/data-grid";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import React, { useMemo, useState } from "react";
import { useRnrViewContext } from "../../../Utils/Data/actions/RnrData";
import { formatTime } from "../../../Utils/Data/Time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputerClassic, faHistory, faUser } from "@fortawesome/pro-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import { Parser } from "json2csv";
import moment from "moment/moment";
import { useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntervalWhen } from "rooks";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        height: "100%",
        width: "100%",
        padding: 8,
        paddingTop: 16,
        paddingBottom: 16,
        display: "flex",
        flexDirection: "column",
    },
}));

function ViewHistoryColumn({ params, intl, trafficStateInfo }) {
    const store = useStore();
    const history = useHistory();
    const [enabled, setEnabled] = useState(false);

    const historyDate = useMemo(() => {
        let maxDelay = 0;
        if (trafficStateInfo.trafficState?.data) {
            trafficStateInfo.trafficState.data.forEach((state) => {
                state.data.forEach((data) => {
                    maxDelay = Math.max(maxDelay, data.delay);
                });
            });
        }
        return moment(params.row.time)
            .add(maxDelay, "milliseconds")
            .add(trafficStateInfo?.driverConfig?.rnr_get_state_frequency * 2, "seconds");
    }, [trafficStateInfo, params]);

    useIntervalWhen(
        () => {
            setEnabled(historyDate.isBefore(moment()));
        },
        1000,
        true,
        true
    );

    return (
        <>
            <Button
                onClick={() => {
                    startUsingHistoryData(store, historyDate);
                    history.push(history.location.pathname.replace("rnrAuditLog", "rnrBasic"));
                }}
                disabled={!enabled}
            >
                <FontAwesomeIcon icon={faHistory} size={"lg"} style={{ marginRight: 4 }} /> {formatForId(intl, "rnrAuditLog.showHistory")}
            </Button>
        </>
    );
}

function useColumns(intl, trafficStates) {
    const getTrafficState = (params) => {
        return {
            trafficState: params.row.lane === "left" ? trafficStates.leftTrafficStates : trafficStates.rightTrafficStates,
            driverConfig: trafficStates.driverConfig,
        };
    };
    const getTrafficStateName = (params) => {
        const trafficStatesComputed = getTrafficState(params).trafficState;
        const displayName = trafficStatesComputed?.data?.find((state) => state.name === params.value)?.displayName;
        return displayName ? displayName : params.value;
    };

    return useMemo(
        () =>
            translateCols(intl, "rnrAuditLog.cols.", [
                {
                    field: "time",
                    valueFormatter: (row) => {
                        return formatTime(row.value);
                    },
                    sortable: true,
                    type: "dateTime",
                    flex: 1,
                },
                {
                    field: "user",
                    valueFormatter: (row) => {
                        return row.value ? row.value.name : formatForId(intl, "rnrAuditLog.system");
                    },
                    renderCell: (params) => {
                        if (params.value) {
                            return (
                                <>
                                    <FontAwesomeIcon icon={faUser} style={{ paddingRight: 3 }} />
                                    &nbsp;{params.value.name}
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <FontAwesomeIcon icon={faComputerClassic} style={{ paddingRight: 3 }} />
                                    &nbsp;{formatForId(intl, "rnrAuditLog.system")}
                                </>
                            );
                        }
                    },
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "lane",
                    valueFormatter: (row) => formatForId(intl, `rnrAuditLog.${row.value}`),
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "state",
                    sortable: true,
                    renderCell: (params) => getTrafficStateName(params),
                    flex: 1,
                },
                {
                    field: "state_change_reason",
                    minWidth: 500,
                    sortable: true,
                    flex: 2,
                },
                {
                    field: "show_history",
                    sortable: false,
                    flex: 1,
                    renderCell: (params) => <ViewHistoryColumn params={params} intl={intl} trafficStateInfo={getTrafficState(params)} />,
                },
            ]),
        [trafficStates]
    );
}

const exportExcludedFields = ["show_history"];

export function RnrAuditLog({}) {
    const historyDate = useHistoryGetData();
    const history = useHistoryFilter(Last7DaysType, historyDate);
    const intl = useIntl();
    const classes = useAlertHistoryStyles();
    const { trafficStates, rnrDriverId, endDevices, domainId, rnrDevices, slices } = useRnrViewContext();

    const columns = useColumns(intl, trafficStates);
    const theme = useTheme();
    const localClasses = useStyles();
    const { auditLogData, loading } = useRnrAuditLog(true, rnrDriverId, history);

    const exportDisabled = _.isEmpty(auditLogData);

    const exportToCsv = () => {
        const formattedData = auditLogData.map((row) => {
            const transformedRow = {};
            columns
                .filter((column) => !exportExcludedFields.includes(column.field))
                .forEach((column) => {
                    const headerName = column.headerName || column.field;
                    transformedRow[headerName] = column.valueFormatter ? column.valueFormatter({ value: row[column.field] }) : row[column.field];
                });
            return transformedRow;
        });

        const parser = new Parser({ delimiter: ";", withBOM: true });
        const csv = parser.parse(formattedData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "audit_log.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <RequirePermission permission={"rnr__audit_log"} domainID={domainId}>
            <div className={localClasses.root}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <div style={{ flex: 1, paddingRight: 8 }}>
                        <DateRangePicker dense={true} value={history.value} disabled={false} onChange={(value) => history.setValue(value)} />
                    </div>
                    <div>
                        <Button variant="contained" color="primary" onClick={exportToCsv} disabled={exportDisabled}>
                            {formatForId(intl, "rnrAuditLog.export")}
                        </Button>
                    </div>
                </div>
                <div style={{ height: theme.spacing(2) }}>&nbsp;</div>
                <LoadingScope loading={loading}>
                    {_.isEmpty(auditLogData) ? (
                        <NoDataMessage />
                    ) : (
                        <DataGrid
                            {...DataGridProps}
                            style={{ height: "100%" }}
                            classes={classes}
                            columns={columns}
                            rows={auditLogData}
                            pagination
                            autoHeight={false}
                            autoPageSize={true}
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: formatForId(intl, "dataGrid.pagination.rowsPerPage"),
                                    labelDisplayedRows: ({ from, to, count }) =>
                                        formatForId(intl, "dataGrid.pagination.displayedRows", {
                                            from,
                                            to,
                                            count,
                                        }),
                                },
                            }}
                        />
                    )}
                </LoadingScope>
            </div>
        </RequirePermission>
    );
}
